.relative {
	position: relative;
}
.padded-v {
	padding: 1rem 10px;
}
.padded-v-big {
	padding: 2rem 10px;
}

.padded {
	padding: 1rem 2rem;
}
.b-l {
	border-left: 1px solid $border-color;
}
.b-r {
	border-right: 1px solid $border-color;
}
.b-t {
	border-top: 1px solid $border-color;
}
.b-b {
	border-bottom: 1px solid $border-color;
}

.m-t {
	margin-top: 1rem;
}
.m-b {
	margin-bottom: 1rem;
}

@media (min-width: 992px) {
	.padded-lg {
		padding: 1rem 2rem;
	}
	.b-l-lg {
		border-left: 1px solid $border-color;
	}
	.b-r-lg {
		border-right: 1px solid $border-color;
	}
	.b-t-lg {
		border-top: 1px solid $border-color;
	}
	.b-b-lg {
		border-bottom: 1px solid $border-color;
	}
}

@media (min-width: 1200px) {
	.padded-xl {
		padding: 1rem 2rem;
	}
	.b-l-xl {
		border-left: 1px solid $border-color;
	}
	.b-r-xl {
		border-right: 1px solid $border-color;
	}
	.b-t-xl {
		border-top: 1px solid $border-color;
	}
	.b-b-xl {
		border-bottom: 1px solid $border-color;
	}
}

.example-column {
	padding: 12px 15px;
	background: #eef5ff;
	border: 1px solid #aec8ff;
	margin-bottom: 15px;
	text-align: center;
	& + .example-column {
		border-left: none;
	}
}
.example-content .progress + .progress {
	margin-top: 1rem;
}

.with-avatar {
	margin-right: 5px;
	img {
		display: inline-block;
		vertical-align: middle;
		border-radius: 50px;
		width: 30px;
		height: auto;
		margin-right: 10px;
	}
	span {
		display: inline-block;
		vertical-align: middle;
	}
}
a.with-avatar {
	span {
		border-bottom: 1px solid $link-color;
	}
}

.add-agent-btn {
	i {
		margin-right: 15px;
		padding-left: 5px;
		font-size: 20px;
		display: inline-block;
		vertical-align: middle;
	}
	span {
		display: inline-block;
		vertical-align: middle;
		border-bottom: 1px solid $primary;
	}
}

.centered-load-more-link {
	display: block;
	padding: 10px;
	padding-bottom: 15px;
	text-align: center;
	cursor: pointer;
	i {
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		margin-right: 5px;
	}
	span {
		display: inline-block;
		vertical-align: middle;
		border-bottom: 1px solid $link-color;
	}
	&:hover {
		span {
			border-bottom-color: darken($link-color, 10%);
		}
	}
	&.smaller {
		font-size: $font-size-sm;
	}
}

.text-faded {
	color: $color-text-faded;
}
.text-bright {
	color: #1d1f28;
}

.ant-checkbox-group label {
	display: block;
}
.custom-checkbox.ant-checkbox-group {
	display: flex;
	flex-wrap: wrap;
	.ant-checkbox-group-item {
		margin-right: -10px;
		margin-left: -10px;
		flex: 0 0 50%;
		max-width: 50%;
	}
}

.ant-time-picker {
	width: 100%;
}

.ant-calendar-picker {
	width: 100%;
}

.ant-calendar-picker-input {
	line-height: 1.5;
	padding: 0.375rem 0.75rem;
}

.labmgt {
	margin-top: 40px;
	width: 100%;
}

.labmgt tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.labmgtTd {
	padding: 15px 0px;
}

.labmgtTdRight {
	padding: 15px 0px;
}

.labmgtKey {
	color: #111;
	font-size: 14px;
	font-weight: bold;
}

.labmgtValue {
	color: #b8b8b8;
	font-size: 12px;
}

.labmgtAddButton {
	position: fixed;
	right: 2em;
	bottom: 2em;
	font-size: 2em;
	background-color: #28a745;
	color: #fff;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: none;
}

.el-tablo .value {
	font-size: 2rem;
}
.el-tablo .value.customfont {
	font-size: 1.2rem;
}

div.react-confirm-alert-overlay {
	z-index: 9999;
}

.btn.filter-btn {
	line-height: 1.69;
}

.loginPage {
	background-image: url('../images/bg9-l.jpg');
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	background-size: cover;
	margin: -10px 0 0 -10px;
}

.fxt-template-animation {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.fxt-content,
.fxt-content h2,
.fxt-content a {
	color: #fff;
}

.fxt-btn-fill {
	color: #3e4b5b;
}
