@-webkit-keyframes slideIn {
	100% {
		right: 5px;
	}
}

@keyframes slideIn {
	100% {
		right: 5px;
	}
}

@-webkit-keyframes slideOut {
	100% {
		right: -340px;
	}
}

@keyframes slideOut {
	100% {
		right: -340px;
	}
}

@-webkit-keyframes spaceboots {
	0% {
		-webkit-transform: translate(2px, 1px) rotate(0deg);
	}
	10% {
		-webkit-transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		-webkit-transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		-webkit-transform: translate(0px, 2px) rotate(0deg);
	}
	40% {
		-webkit-transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		-webkit-transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		-webkit-transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		-webkit-transform: translate(2px, 1px) rotate(-1deg);
	}
	80% {
		-webkit-transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		-webkit-transform: translate(2px, 2px) rotate(0deg);
	}
	100% {
		-webkit-transform: translate(1px, -2px) rotate(-1deg);
	}
}

@keyframes spaceboots {
	0% {
		-webkit-transform: translate(2px, 1px) rotate(0deg);
	}
	10% {
		-webkit-transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		-webkit-transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		-webkit-transform: translate(0px, 2px) rotate(0deg);
	}
	40% {
		-webkit-transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		-webkit-transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		-webkit-transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		-webkit-transform: translate(2px, 1px) rotate(-1deg);
	}
	80% {
		-webkit-transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		-webkit-transform: translate(2px, 2px) rotate(0deg);
	}
	100% {
		-webkit-transform: translate(1px, -2px) rotate(-1deg);
	}
}

.lab-form {
	position: fixed;
	width: 420px;
	z-index: 4000;
	right: -410px;

	&.hide {
		-webkit-animation: slideOut 0.5s forwards;
		-webkit-animation-delay: 2s;
		animation: slideOut 0.5s forwards;
		animation-delay: 2s;

		-webkit-animation-name: spaceboots;
		-webkit-animation-duration: 5s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		-webkit-transform-origin: 50% 50%;
	}

	&.show {
		-webkit-animation: slideIn 0.5s forwards;
		-webkit-animation-delay: 0.8s;
		animation: slideIn 0.5s forwards;
		animation-delay: 0.8s;
	}
}
