// variables
$content-panel-bg-dark: #2d3756;
$black: #000000;
$white: #ffffff;
$primary: #047bf8;
$secondary: #292b2c;
$success: #28a745;
$backgroundColor: #f2f4f8;
$info: #456CF9;

// Add styles here you want to override
a {
	cursor: pointer;
}
button {
	outline: 0 none !important;
}
.element-wrapper {
	padding-bottom: 1.5rem;
}
.ant-tooltip {
	z-index: 9999;
}
.ant-popover {
	z-index: 9999;

	&.vitals {
		.ant-popover-inner-content {
			padding: 0;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type='number'] {
			-moz-appearance: textfield;
		}
	}
}
.text-bold {
	font-weight: bold !important;
}
.alert {
	border-right: 0 none;
	border-left: none;
	border-radius: 0;
	padding: 0.25rem 1.25rem;
}
.balance {
	.balance-title {
		font-size: 0.8rem;
	}
	.strong {
		margin-top: -2px;

		small {
			font-weight: bold !important;
			font-size: 1.08rem;
		}
	}
}
.ReactModal__Body--open {
	overflow: hidden;
}
.modal-backdrop {
	z-index: 2040;
}
.modal {
	z-index: 2050;
}
.top-modal {
	.modal-dialog {
		max-width: 98%;
	}
	overflow: scroll;
}
.menu-w {
	width: 18%;
}
.top-menu {
	margin-bottom: 0 !important;
	width: 100%;
	position: relative !important;
	.modal-content {
		border-radius: 6px;
		overflow-x: hidden;
	}
	.content-w {
		border-radius: 0 !important;

		.content-box {
			padding: 2rem 1rem;
		}
	}
	.main-menu {
		padding: 0 !important;
	}
	li {
		border-left: 1px solid #f2f4f8 !important;
		border-right: 1px solid #f2f4f8 !important;
		border-top: 1px solid #f2f4f8 !important;
		border-bottom: 1px solid #f2f4f8 !important;

		a {
			flex-direction: column;
			align-items: flex-start !important;
			height: 75px;
			transition: none !important;
		}

		.step {
			white-space: nowrap;
			font-size: 0.8rem;
		}

		span {
			white-space: normal !important;
			padding: 0 !important;
			font-size: 0.85rem;
		}

		&.active {
			a {
				color: #293145 !important;
				background-color: $backgroundColor;
			}
		}
	}
}
.pagination-center {
	position: relative;
	justify-content: flex-end;
}
.ant-pagination-total-text {
	position: absolute;
	left: 0;
	top: 0;
}

.element-wrapper {
	.element-header {
		&.text-center {
			&:after {
				right: 0;
				margin: auto;
				width: 30px;
			}
		}
	}
}

.row-expand-icon {
	color: #1890ff;
	text-decoration: none;
	cursor: pointer;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	display: inline-block;
	width: 17px;
	height: 17px;
	color: inherit;
	line-height: 13px;
	text-align: center;
	background: $white;
	border: 1px solid #e8e8e8;
	border-radius: 2px;
	outline: none;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.row-collapsed::after {
	content: '+';
}

.row-expanded::after {
	content: '-';
}

.expanded-row {
	background: #fbfbfb !important;

	.table {
		background: #fbfbfb;
	}
	.table-striped tr {
		background: #fbfbfb;
	}
	.table-striped tbody tr:nth-of-type(odd) {
		background: #fbfbfb;
	}
}

.onboarding-modal {
	.onboarding-content {
		margin: 20px 0;

		&.with-gradient {
			padding: 20px 32px;
		}
	}
}

.table {
	.row-actions {
		a {
			&.primary {
				color: $primary !important;
			}
			&.secondary {
				color: $secondary !important;
			}
			&.success {
				color: $success !important;
			}
			&.danger {
				color: $dark-red !important;
			}
			&.info {
				color: $info !important;
			}
		}
		.os-icon-basic-2-259-calendar:before {
			color: $dark-red;
		}
	}
}
.lds-loading {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 80px;
	height: 80px;
}
.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ripple div {
	position: absolute;
	border: 4px solid #cef;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}
.leave-box {
	.element-header {
		margin-top: 24px;
	}
	.table-striped tbody tr:nth-of-type(odd) {
		background: #ffffff;
	}
	.table {
		background: #ffffff;
	}
	.table-striped tr {
		background: #ffffff;
	}
}
.payroll-details {
	background-color: #fff6f2;
	border-radius: 6px;
	padding: 20px 25px;

	&.deductions {
		background-color: #f6f2ff;
	}
}
.settings-menu {
	display: flex;
	-webkit-box-align: stretch;
	align-items: stretch;
	background-color: #fff;
	border-radius: 6px;

	.ae-side-menu {
		flex: 0 0 210px;
	}

	&.compact-menu {
		.ae-side-menu {
			-webkit-box-flex: 0;
			flex: 0 0 60px;
			text-align: center;

			li a span {
				display: none;
			}
		}
	}
}
.color-scheme-dark {
	.settings-menu {
		background-color: $content-panel-bg-dark;
	}
	.table .row-actions a {
		color: $white !important;
	}
	.search-with-suggestions-w .search-with-suggestions-modal {
		background-color: $content-panel-bg-dark;
	}
}

.search-with-suggestions-w {
	&.over-search-field {
		width: 420px;
	}
	.search-with-suggestions-modal {
		.element-search input {
			padding: 8px 26px 8px 31px;
		}
		.close-search-suggestions {
			right: 4px;
		}
		.btn-search-suggestions {
			right: 28px;
		}
	}
}

.top-bar.color-scheme-light .top-menu-controls .element-search input,
.top-bar.color-scheme-transparent .top-menu-controls .element-search input {
	background-color: $white;
}

.form-group {
	.custom-date-input {
		.react-datepicker-wrapper {
			width: 100%;

			.react-datepicker__input-container {
				&::before {
					content: '\e972';
					font-family: 'osfont' !important;
					font-style: normal;
					font-weight: normal;
					font-feature-settings: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					font-size: 15px;
					color: #047bf8;
					position: absolute;
					top: 50%;
					left: 10px;
					transform: translateY(-50%);
				}

				.form-control {
					padding-left: 35px;
				}
			}
		}
	}
}

.ant-popover {
	&.download-roster,
	&.upload-roster,
	&.view-vaccine,
	&.select-drug,
	&.set-credit-limit,
	&.select-bed,
	&.show-note {
		.ant-popover-inner-content {
			padding: 0;
		}
	}
}

.bg-primary {
	background-color: $primary;
}
.bg-secondary {
	background-color: $secondary;
}
.bg-success {
	background-color: $success;
}

.onboarding-modal .onboarding-content.with-gradient {
	padding: 0px 12px;
}
.form-header,
.form-desc {
	margin-bottom: 0 !important;
}
.auth-box-w {
	.logo-w {
		padding: 10%;
	}
}
.vital {
	.col-4 {
		> div {
			margin-top: 60px;
			line-height: 24px;
		}
	}

	.reading {
		font-size: 1.4rem;
		margin: 14px 0;
	}

	.new-reading {
		color: $primary;
		cursor: pointer;
	}
}

.diagnosis-type {
	.float-right {
		width: 74px;
		display: flex;
		line-height: 30px;

		input {
			height: 27px;
			width: 25px;
		}

		label {
			margin-bottom: 0 !important;
		}
	}
}
.encounter {
	.form-group {
		margin-bottom: 0 !important;
		margin-top: 12px;
	}

	input[type='checkbox'] {
		display: inline-block;
		width: auto;
		margin-right: 6px;
	}

	input[type='radio'] {
		display: inline-block;
		width: auto;
		margin-right: 6px;
	}
}
.content-w-l-18 {
	margin-left: 18%;
}
.content-w-l-8 {
	margin-left: 8%;
}
.text-info {
	&.invert {
		margin-top: 4px;
		background-color: #d1ecf1 !important;
		border-top: 2px #bee5eb solid;
		border-bottom: 2px #bee5eb solid;
		color: #0c5460 !important;
		padding: 0.15rem 1.25rem;
		font-size: 0.85rem;
	}
}
.encounter-menu {
	display: flex;
}
.encounter-box {
	height: 550px;
	overflow-y: scroll;
}
.posit-top {
	position: absolute;
	right: 12px;
	top: 0;
	width: 100%;
	text-align: right;

	.form-group {
		margin-bottom: 0;
	}
}
.custom-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #235a94;
	z-index: 0;
	display: none;

	&.fade {
		opacity: 0;
	}
}
.modal-open {
	.custom-backdrop {
		&.fade {
			opacity: 0.3 !important;
			z-index: 1999 !important;
			display: block !important;
		}
	}
}
.alert-custom {
	background-color: $white;
	font-size: 0.88rem !important;
	font-weight: bold;
	border-top: 1px rgba(69, 76, 96, 0.29) solid;
	border-bottom: 1px rgba(69, 76, 96, 0.29) solid;
	color: #293145;
	padding: 8px 0;
}

.alert-custom2 {
	background-color: rgba(69, 76, 96, 0.09);
	font-size: 0.88rem !important;
	font-weight: bold !important;
	border-top: 1px rgba(69, 76, 96, 0.29) solid;
	border-bottom: 1px rgba(69, 76, 96, 0.29) solid;
	color: #293145;
	padding: 8px 0;
}

.link-primary {
	color: $primary !important;
}