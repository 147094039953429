.fxt-animation {
	position: relative;
	z-index: 1;
	width: 100%;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;

	.has-danger .form-control {
		border-color: #e65252 !important;
	}

	&.template {
		background: url('../images/bg.jpg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		background-color: #011f42;
	}

	.bg-overlay {
		background-image: url('../images/overlay.png');
		min-height: 100vh;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		padding: 60px 30px;
	}

	.fxt-content {
		max-width: 460px;
		width: 100%;
		margin-left: 15vw;
	}

	.logo-header {
		text-align: center;
		margin-bottom: 50px;

		img {
			height: 90px;
			max-width: 100%;
		}
	}

	.fxt-form {
		.form-group {
			margin-bottom: 12px;

			.form-control {
				min-height: 45px;
				-webkit-box-shadow: none;
				box-shadow: none;
				border: 1px solid rgba(241, 241, 241, 0.25);
				padding: 15px 15px;
				background-color: rgba(1, 31, 66, 0.3);
				color: #fff;

				&::placeholder {
					color: #a0a0a0;
				}
			}

			.pre-icon {
				font-size: 20px;
			}

			.input-group {
				overflow: hidden;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}

		.fxt-btn-fill {
			font-family: 'Roboto', sans-serif;
			cursor: pointer;
			display: inline-block;
			font-size: 17px;
			font-weight: 500;
			-webkit-box-shadow: none;
			box-shadow: none;
			outline: none;
			border: 0;
			color: #fff;
			border-radius: 3px;
			background-color: #1fbe66;
			padding: 10px 36px;
			margin-bottom: 10px;
			width: 100%;
			-webkit-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			text-decoration: none;

			&.custom-bg {
				background-color: #17a2b8 !important;
			}
		}
	}
}
